import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import CambridgeImg1 from '../assets/images/cambridge/cambridge-college-lycee-international-lafayette1.jpg'
import CambridgeImg2 from '../assets/images/cambridge/cambridge-college-lycee-international-lafayette2.jpg'
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import '../../translation';
import { useTranslation } from 'react-i18next';

const AnglaisCambridge = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const { t } = useTranslation();
    const tAnglaisCambridge = useTranslation('anglaisCambridge').t;

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Centre d'examen Cambridge | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette est classé par l'Université de Cambridge comme l'un des établissements scolaires ayant obtenu les meilleurs résultats au MONDE." />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth'>
                    <div className='text'>
                        <div className='special-title'>{tAnglaisCambridge('cambridge-title')}</div>
                        <p className='text-center' dangerouslySetInnerHTML={{__html: tAnglaisCambridge('t1')}}></p><br/>
                        {/*<br /><div className='cambridge-logo'>*/}
                        {/*    <img src={CambridgeImg} alt='École Internationale Bilingue Pégomas - Cambridge' />*/}
                        {/*</div><br />*/}
                        <p style={{textAlign: 'center'}}><strong><em>{tAnglaisCambridge('cambridge-text2')}</em></strong></p>
                        <br />
                        <p>{tAnglaisCambridge('cambridge-text3')}</p>
                        <br />
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-12 col-md-6'>
                                <img width='100%' src={CambridgeImg2} />
                            </div>
                            <div className='col-12 col-md-6'>
                                <img width='100%' src={CambridgeImg1} />
                            </div>

                        </div> <br />
                        <br />
                        <div className='content-title mb-0'>
                            {tAnglaisCambridge('cambridge-text4')}
                        </div>
                        <p>
                            {tAnglaisCambridge('cambridge-text5')}
                        </p> <br />
                        <div className='content-title mb-0'>
                            {tAnglaisCambridge('cambridge-text6')}
                        </div>
                        <p>
                            {tAnglaisCambridge('cambridge-text7')}
                        </p> <br />
                        <div className='content-title mb-0'>
                            {tAnglaisCambridge('cambridge-text8')}
                        </div>
                        <p>
                            {tAnglaisCambridge('cambridge-text9')}
                        </p> <br />
                        <div className='content-title mb-0'>
                            {tAnglaisCambridge('cambridge-text10')}
                        </div>
                        <p>{tAnglaisCambridge('cambridge-text11')}</p>
                        <br/>
                        <div className='grayTextCambridge'>
                            <div className='content-title mb-0'>LEVELS</div>
                            <div className='content-title mb-0'> Key English Test (KET)</div>
                            <p>The Key English Test is an elementary level examination for learners of English. Learners are expected to have developed many of the basic linguistic ‘survival skills’ needed to interact socially or at work in an English speaking environment.</p>

                            <div className='content-title mb-0'>Preliminary English Test (PET)</div>
                            The Cambridge level 2 exam. It tests language ability at lower-intermediate level and is about two-thirds of the way to the very popular FCE.

                            <div className='content-title mb-0'>First Certificate in English (FCE)</div>
                            At Cambridge level 3, this exam is at the upper-intermediate or 'independent user' level. FCE is the most widely taken exam and has the most widespread recognition. It is taken for employment and study purposes.

                            <div className='content-title mb-0'>Certificate of Advanced English (CAE)</div>
                            A Cambridge level 4 exam, this offers a lower-advanced language qualification for those who need to use English in their jobs. It is mostly taken by university students and young professionals. Many universities recognise CAE as an English language entry requirement.

                            <div className='content-title mb-0'>Certificate of Proficiency in English (CPE)</div>
                            A top grade in this exam certifies that the candidate has near-native speaker ability; therefore it fulfils entry requirements in English language by British universities and many universities in other English-speaking countries and by other professional bodies worldwide.
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default AnglaisCambridge;